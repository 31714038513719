import React, { useRef, useState } from "react";
import styled from "styled-components";
import arrow_yellow from "../assets/images/arrow_yellow.svg";

const StyledCarousel = styled.div`
  overflow: hidden;

  flex: 1;
  position: relative;
  display: flex;
  .comentarios_container {
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap !important;
    margin: auto;
    position: relative;
    z-index: 9;
    .comentario {
      width: calc(100vw - 80px);
      max-width: 350px;
      height: 190px;
      background: pink;
      border-radius: 10px;
      background: rgb(245, 245, 245);
      padding: 15px;

      margin-left: 25px;
      margin-right: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .user_name {
        font-family: DIN_Italic_Bold;
      }

      .user_comen {
        margin-bottom: 0;
      }
    }
  }

  .flechas {
    position: absolute;
    width: calc(100vw - 30px);
    max-width: 400px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    z-index: 10;
    img {
      pointer-events: all;
      width: 15px;
      cursor: pointer;
    }

    .left {
      transform: rotate(180deg);
    }
    .right {
      margin-left: auto;
    }

    .hidden {
      display: none;
    }
  }
`;

const Carousel = ({ comentarios }) => {
  const SlideRef = useRef(null);
  const [state, setstate] = useState(0);

  const Jump = (item) => {
    const Sliders = SlideRef.current;
    const PrimerSlider = Sliders.children[0];
    const element = document.getElementById("slide_0");
    const margin = parseInt(getComputedStyle(element)["margin-right"]);
    const marginL = parseInt(getComputedStyle(element)["margin-left"]);
    var distance = PrimerSlider.offsetWidth + margin + marginL;
    Sliders.style.transition = `500ms ease-in-out all`;
    Sliders.style.transform = `translateX(-${distance * item}px)`;
    setstate(item);
  };

  return (
    <StyledCarousel className="comentarios_">
      <div className="flechas">
        <img
          src={arrow_yellow}
          alt=""
          className={`left ${state === 0 ? "hidden" : ""}`}
          onClick={() => Jump(state - 1)}
        />
        <img
          src={arrow_yellow}
          alt=""
          className={`right ${
            state === comentarios.length - 1 ? "hidden" : ""
          }`}
          onClick={() => Jump(state + 1)}
        />
      </div>
      <div className="comentarios_container" ref={SlideRef}>
        {comentarios.map((coment, idx) => {
          return (
            <div className="comentario" key={idx} id={`slide_${idx}`}>
              {coment.Experiencia ? (
                <p className="user_comen">{coment.Experiencia}</p>
              ) : null}
              {/* {coment.Nombre ? (
                <p className="user_name">- {coment.Nombre}</p>
              ) : null} */}
            </div>
          );
        })}
      </div>
    </StyledCarousel>
  );
};

export default Carousel;
