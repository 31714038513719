import React, { Component, useContext, useEffect } from "react";
import { Context } from "../Context";
import styled from "styled-components";
import Container from "./Container";
import Card from "./Card";
import LoaderSpinner from "../Utilities/SpinnerLoader";
import sdk from "../sdk/DekalbSDK";
import Mapa from "../components/Mapa";

// background: #F5F5F5;
const StyledResultadosDekalb = styled.section`
  .div-grafica-estado {
    text-align: center;
  }
  .div-datos-municipio {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 5px 0;
  }
  .div-nombre-municipio {
    background: #e5e5e5;
    border-radius: 10px;
    width: 80%;
    padding: 11.5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .div-descripcion-municipio {
    background: #ffd22b;
    border-radius: 10px;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 11.5px 0;
    min-height: 15rem;
  }
  #mapa-sinaloa-svg {
    cursor: pointer;
    margin-top: 20px;
  }
  .active path {
    fill: #ffd22b;
  }
  .active path:hover {
    fill: #ffd22b;
  }

  background: #ffffff;
  padding: 4rem 0px;
  .grafica-estado {
    label {
      font-family: DIN_Italic_Bold;
      font-size: 14px;
    }
    h1 {
      text-align: center;
    }

    .selects_ {
      .select_ {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        select {
          background: #ffd22b;
          border-radius: 4px;
          font-weight: bold;
          text-align: center;
          font-size: 14px;
        }
      }
    }

    .body_maps {
      display: flex;
      flex-direction: column;

      .card__ {
        margin-bottom: 40px;
      }
    }
  }

  @media (min-width: 992px) {
    .grafica-estado {
      .selects_ {
        display: flex;
        .select_ {
          flex: 1;

          margin-bottom: 0px;
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .body_maps {
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .card__ {
          margin-bottom: 0px;
          margin: auto;
        }
      }
    }

    #mapa-sinaloa-svg {
      margin-top: 0px;
    }
  }
`;
export default class GraficaEstado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      temporada: {},
      temporadas: [],
      //
      regiones: [],
      region: {},
      rendimiento_region: null,
      //
      subRegiones: [],
      subRegion: {},
      rendimiento_subRegion: null,
      //
      estado: {},
      estados: [],
      rendimiento_estado: null,
      //
      municipio: {},
      municipios: [],
      rendimiento_municipio: null,

      fetch: false,
    };
  }
  async componentDidMount() {
    this.setState({
      fetch: true,
    });
    const Temporadas = await sdk.getTemporadas();

    if (Temporadas) {
      this.setState({
        temporadas: Temporadas.Informacion,
        fetch: false,
      });
    }
  }

  getOptions = (arr, mayus) => {
    if (arr.length === 0) {
      return <option value={0}> sin información</option>;
    } else if (arr.length === 1) {
      return arr.map((item, idx) => {
        return (
          <option value={item.Id} key={idx}>
            {this.capitalize(item.Descripcion || item.Nombre, mayus)}
          </option>
        );
      });
    } else {
      return (
        <>
          <option value={0}> </option>
          {arr.map((item, idx) => {
            return (
              <option value={item.Id} key={idx}>
                {this.capitalize(item.Descripcion || item.Nombre, mayus)}
              </option>
            );
          })}
        </>
      );
    }
  };

  capitalize = (s, mayus) => {
    if (typeof s !== "string") return "";
    var arr = s.split(" ").map((e) => e.toLowerCase());

    if (mayus) {
      return arr
        .map((e) => e.charAt(0).toUpperCase() + e.slice(1))
        .join(" ")
        .toUpperCase();
    } else {
      return arr.map((e) => e.charAt(0).toUpperCase() + e.slice(1)).join(" ");
    }
  };

  async getRegiones(idTemporada) {
    if (idTemporada !== 0) {
      const Regiones = await sdk.getRegionPorTemprada(idTemporada);

      if (Regiones.Informacion.length === 1) {
        this.setState({
          region: Regiones.Informacion[0],
          regiones: Regiones.Informacion,
        });

        const Rendimiento_region = await sdk.getRendimientos(this.state);
        this.setState({
          rendimiento_region: Rendimiento_region.Informacion,
        });

        await this.getSubRegiones(Regiones.Informacion[0].Id);
      } else {
        this.setState({
          fetch: false,
        });
      }

      return Regiones.Informacion;
    } else {
      this.setState({
        regiones: [],
        fetch: false,
      });
    }
  }

  async getSubRegiones(id_region) {
    if (id_region !== 0) {
      const SubRegiones = await sdk.getSubRegiones(
        id_region,
        this.state.temporada.Id
      );

      if (SubRegiones.Informacion.length === 1) {
        this.setState({
          subRegion: SubRegiones.Informacion[0],
          subRegiones: SubRegiones.Informacion,
        });
        const Rendimiento_subRegion = await sdk.getRendimientos(this.state);
        this.setState({
          rendimiento_subRegion: Rendimiento_subRegion.Informacion,
        });

        await this.getEstados(SubRegiones.Informacion[0].Id);
      } else {
        this.setState({
          subRegiones: SubRegiones.Informacion,
          fetch: false,
        });
      }

      return SubRegiones.Informacion;
    } else {
      this.setState({
        subRegiones: [],
        fetch: false,
      });
    }
  }

  async getEstados(id_subregion) {
    if (id_subregion !== 0) {
      const Estados_subRegion = await sdk.getEstadosByRegion(
        id_subregion,
        this.state.temporada.Id
      );

      if (Estados_subRegion.Informacion.length === 1) {
        this.setState({
          estado: Estados_subRegion.Informacion[0],
          estados: Estados_subRegion.Informacion,
        });
        const Rendimiento_subRegion = await sdk.getRendimientos(this.state);
        this.setState({
          rendimiento_estado: Rendimiento_subRegion.Informacion,
        });
        await this.getMunicipios(Estados_subRegion.Informacion[0].Id);
      } else {
        this.setState({
          estados: Estados_subRegion.Informacion,
          fetch: false,
        });
      }

      return Estados_subRegion.Informacion;
    } else {
      this.setState({
        estados: [],
        fetch: false,
      });
    }
  }

  async getMunicipios(id_estado) {
    if (id_estado !== 0) {
      const Municipios = await sdk.getMunicipios(
        id_estado,
        this.state.temporada.Id || -1
      );

      if (Municipios.Informacion.length === 1) {
        this.setState({
          municipio: Municipios.Informacion[0],
          municipios: Municipios.Informacion,
        });
        const Rendimiento_municipio = await sdk.getRendimientos(this.state);
        this.setState({
          rendimiento_municipio: Rendimiento_municipio.Informacion,
          fetch: false,
        });
      } else {
        this.setState({
          municipios: Municipios.Informacion,
          fetch: false,
        });
      }

      return Municipios.Informacion;
    } else {
      this.setState({
        municipios: [],
        fetch: false,
      });
    }
  }

  async onChangeTemporada(e) {
    const id_Temporada = parseInt(e.target.value);

    this.setState({
      fetch: true,
      region: {},
      regiones: [],
      rendimiento_region: null,

      subRegiones: [],
      subRegion: {},
      rendimiento_subRegion: null,

      estado: {},
      estados: [],
      rendimiento_estado: null,

      municipio: {},
      municipios: [],
      rendimiento_municipio: null,
    });

    if (id_Temporada === 0) {
      this.setState({
        fetch: false,
      });
    }

    var Temporada;

    if (id_Temporada > 0) {
      Temporada = this.state.temporadas.filter(
        (tem) => tem.Id === id_Temporada
      )[0];
    } else {
      Temporada = {};
      this.setState({
        region: {},
      });
    }

    this.setState({
      temporada: Temporada,
    });

    await this.getRegiones(id_Temporada);
  }

  async onChangeRegion(e) {
    const id_Region = parseInt(e.target.value);
    this.setState({
      fetch: true,
      subRegiones: [],
      subRegion: {},
      rendimiento_subRegion: null,

      estado: {},
      estados: [],
      rendimiento_estado: null,

      municipio: {},
      municipios: [],
      rendimiento_municipio: null,
    });

    var Region;

    if (id_Region > 0) {
      Region = await this.state.regiones.filter(
        (tem) => tem.Id === id_Region
      )[0];
    } else {
      Region = {};
    }

    this.setState({
      region: Region,
    });

    if (Region.Id) {
      var Rendimiento_region;
      Rendimiento_region = await sdk.getRendimientos(this.state);
      Rendimiento_region = Rendimiento_region.Informacion;

      this.setState({
        rendimiento_region: Rendimiento_region,
      });
    }

    await this.getSubRegiones(id_Region);
  }

  async onChangeSubRegion(e) {
    const id_subReg = parseInt(e.target.value);
    this.setState({
      fetch: true,
      estado: {},
      estados: [],
      rendimiento_estado: null,

      municipio: {},
      municipios: [],
      rendimiento_municipio: null,
    });
    var SubRegion;

    if (id_subReg > 0) {
      SubRegion = await this.state.subRegiones.filter(
        (tem) => tem.Id === id_subReg
      )[0];
    } else {
      SubRegion = {};
    }

    this.setState({
      subRegion: SubRegion,
    });

    var Rendimiento_SubRegion;
    Rendimiento_SubRegion = await sdk.getRendimientos(this.state);
    Rendimiento_SubRegion = Rendimiento_SubRegion.Informacion;

    this.setState({
      rendimiento_subRegion: Rendimiento_SubRegion,
    });

    await this.getEstados(id_subReg);
  }

  async onChangEstado(e) {
    const id_Estado = parseInt(e.target.value);
    this.setState({
      fetch: true,
      municipio: {},
      municipios: [],
      rendimiento_municipio: null,
    });
    var Estado;

    if (id_Estado > 0) {
      Estado = await this.state.estados.filter(
        (tem) => tem.Id === id_Estado
      )[0];
    } else {
      Estado = {};
    }

    this.setState({
      estado: Estado,
    });

    var Rendimiento_estado;
    Rendimiento_estado = await sdk.getRendimientos(this.state);
    Rendimiento_estado = Rendimiento_estado.Informacion;

    this.setState({
      rendimiento_estado: Rendimiento_estado,
    });

    this.getMunicipios(id_Estado);
  }

  async onChangeMunicipio(e) {
    const idMunicipio = parseInt(e.target.value);
    this.setState({
      fetch: true,
    });
    var Municipio;

    if (idMunicipio > 0) {
      Municipio = await this.state.municipios.filter(
        (tem) => tem.Id === idMunicipio
      )[0];
    } else {
      Municipio = {};
    }

    this.setState({
      municipio: Municipio,
    });

    var Rendimiento_municipio;
    Rendimiento_municipio = await sdk.getRendimientos(this.state);
    Rendimiento_municipio = Rendimiento_municipio.Informacion;

    this.setState({
      rendimiento_municipio: Rendimiento_municipio,
      fetch: false,
    });
  }

  render() {
    return (
      <UseContext data={this.state}>
        <StyledResultadosDekalb>
          <Container>
            <div className="grafica-estado">
              <h1>HÍBRIDO POR ZONA REGISTRADO</h1>
              <br />
              <div className="selects_">
                <div className="select_">
                  <label htmlFor="">Selecciona una temporada</label>
                  <select
                    name="temporada"
                    className="form-control select-temporada"
                    onChange={(e) => this.onChangeTemporada(e)}
                    value={this.state.temporada.id}
                    disabled={this.state.temporadas ? false : true}
                  >
                    {this.state.temporadas
                      ? this.getOptions(this.state.temporadas, true)
                      : null}
                  </select>
                </div>
                <div className="select_">
                  <label htmlFor="">Selecciona una región</label>
                  <select
                    name="region"
                    className="form-control select-region"
                    onChange={(e) => this.onChangeRegion(e)}
                    value={this.state.region.Id}
                    disabled={this.state.regiones.length === 0 ? true : false}
                  >
                    {this.state.regiones
                      ? this.getOptions(this.state.regiones)
                      : null}
                  </select>
                </div>
                <div className="select_">
                  <label htmlFor="">Selecciona una subregión</label>
                  <select
                    name="subRegion"
                    className="form-control select-subregion"
                    onChange={(e) => this.onChangeSubRegion(e)}
                    value={this.state.subRegion.Id}
                    disabled={
                      this.state.subRegiones.length === 0 ? true : false
                    }
                  >
                    {this.state.subRegiones
                      ? this.getOptions(this.state.subRegiones)
                      : null}
                  </select>
                </div>
                <div className="select_">
                  <label htmlFor="">Selecciona un estado</label>
                  <select
                    name="id_estado"
                    className="form-control select-estado"
                    onChange={(e) => this.onChangEstado(e)}
                    value={this.state.estado.Id}
                    disabled={this.state.estados.length === 0 ? true : false}
                  >
                    {this.state.estados
                      ? this.getOptions(this.state.estados)
                      : null}
                  </select>
                </div>
                <div className="select_">
                  <label htmlFor="">Selecciona un municipio</label>
                  <select
                    name="id_estado"
                    className="form-control select-municipios"
                    onChange={(e) => this.onChangeMunicipio(e)}
                    value={this.state.municipio.Id}
                    disabled={this.state.municipios.length === 0 ? true : false}
                  >
                    {this.state.municipios
                      ? this.getOptions(this.state.municipios)
                      : null}
                  </select>
                </div>
              </div>
              <br />
              <br />
              {this.state.temporadas ? (
                <div className="body_maps">
                  <Card state={this.state} />
                  <Mapa
                    state={this.state}
                    onChangeRegion={this.onChangeRegion.bind(this)}
                    onChangeSubRegion={this.onChangeSubRegion.bind(this)}
                    onChangEstado={this.onChangEstado.bind(this)}
                    onChangeMunicipio={this.onChangeMunicipio.bind(this)}
                  />
                </div>
              ) : (
                <LoaderSpinner />
              )}
            </div>
          </Container>
        </StyledResultadosDekalb>
      </UseContext>
    );
  }
}

const UseContext = (props) => {
  const [context, setContext] = useContext(Context);

  useEffect(() => {
    const { data } = props;
    const obt = {
      id_estado: data.estado.Id ? data.estado.Id : -1,
      id_municipio: data.municipio.Id ? data.municipio.Id : -1,
      id_region: data.region.Id ? data.region.Id : -1,
      id_subReg: data.subRegion.Id ? data.subRegion.Id : -1,
      id_temporada: data.temporada.Id ? data.temporada.Id : -1,
    };

    const obtCTN = {
      id_estado: context.id_estado,
      id_municipio: context.id_municipio,
      id_region: context.id_region,
      id_subReg: context.id_subReg,
      id_temporada: context.id_temporada,
    };

    if (!data.fetch) {
      if (JSON.stringify(obt) !== JSON.stringify(obtCTN)) {
        setContext({
          ...context,
          id_estado: data.estado.Id ? data.estado.Id : -1,
          id_municipio: data.municipio.Id ? data.municipio.Id : -1,
          id_region: data.region.Id ? data.region.Id : -1,
          id_subReg: data.subRegion.Id ? data.subRegion.Id : -1,
          id_temporada: data.temporada.Id ? data.temporada.Id : -1,
        });
      }
    }
  }, [props]);

  return props.children;
};
