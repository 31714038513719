import React from "react";
import styled from "styled-components";
import Container from "./Container";

// background: #F5F5F5;

const StyledResultadosDekalb = styled.section`
  background: #f5f5f5;
  .texto-resultados-dekalb {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    p{
      font-family: "DIN_Normal_light"; //
    }
  }
  .texto-resultados-dekalb div {
    height: 100%;
  }

  padding: 4rem 0px;
`;

export default function ResultadosDekalb() {
  return (
    <StyledResultadosDekalb>
      <Container>
        <div className="texto-resultados-dekalb">
          <div>
            <h1>RESULTADOS DEKALB®</h1>
            <p>
              Tenemos un equipo de híbridos con cualidades y desempeños
              específicos para que puedas darle a tu campo, la semilla que
              necesita. Conoce los resultados que tiene cada uno de ellos por
              región.
            </p>
            <br />
            <p>Eres parte del equipo DEKALB®.</p>
          </div>
        </div>
      </Container>
    </StyledResultadosDekalb>
  );
}
