import React, { Component } from "react";
import Spinner from "react-bootstrap/Spinner";

export default class GraficaEstado extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div
        className="spin"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Spinner
          animation="border"
          style={{
            color: this.props.color ? this.props.color : "#fbca32",
            margin: "auto",
            display: "flex",
            alignSelf: "center",
          }}
        >
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }
}
