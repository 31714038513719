import React from "react";

import styled from "styled-components";
import Container from "./Container";

// imagenes

const Styledfooter = styled.section`
  padding: 2.5rem 2.5rem 3rem 2.5rem;

  background: rgb(217, 217, 217);
  .footer-top {
    // border: 1px solid red;
    display: flex;
    padding: 1rem 0;
    display: flex;
    flex-direction: column-reverse;

    .footer-section {
      // border: 1px solid red;

      display: flex;
      flex-direction: column;
      margin-right: 3.5rem;

      a {
        margin-bottom: 0.5rem;

        color: black;
        font-family: 'HCo Gotham SSm';
        font-size: 1.6rem;
        letter-spacing: 1px;
        &:hover {
          text-decoration: none;
        }
      }
      button {
        background: rgb(79, 67, 43);
        font-size: 1.4rem;
        color: white;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 1rem 2rem;
        border: 0;
      }
    }
  }

  .footer-bottom {
    text-align: center;
    font-size: 1.2rem;
    color: black;
    font-family: 'HCo Gotham SSm';

    padding: 1rem;
    a {
      font-size: 1.2rem;
      color: black;
      font-family: 'HCo Gotham SSm';
      text-decoration: underline;
    }
  }

  @media (min-width: 600px) {
    .footer-top {
      flex-direction: row;
    }
  }
`;
function Nav() {
  return (
    <Styledfooter>
      <Container>
        <div className="footer-top">
          <div className="footer-section">
            <a className="footer-link" href="https://www.dekalb.com.mx/es-mx/acerca-de-dekalb-.html">
              Acerca de DEKALB®
            </a>
            <a className="footer-link" href="https://www.dekalb.com.mx/es-mx/dekalb--contigo.html#display=cards&q=">
              DEKALB® Contigo
            </a>
            <button>Cookie Settings</button>
          </div>
          <div className="footer-section">
            <a className="footer-link" href="https://www.dekalb.com.mx/es-mx/productos-dekalb-/maiz.html">
              Productos DEKALB®
            </a>
            <a className="footer-link" href="https://www.dekalb.com.mx/es-mx/resultados-probados.html">
              Resultados Probados
            </a>
          </div>
          <div className="footer-section">
            <a className="footer-link" href="https://pages.mko.dekalb.com.mx/mx-dk-contact-us.html?adobe_mc=MCMID%3D32955331620841990133351718353533063110%7CMCORGID%3D8A720E205666B0F57F000101%2540AdobeOrg%7CTS%3D1651681522">
              Contáctanos
            </a>
            <a className="footer-link" href="https://www.dekalb.com.mx/es-mx/donde-encontrarnos-.html?adobe_mc=MCMID%3D32955331620841990133351718353533063110%7CMCORGID%3D8A720E205666B0F57F000101%2540AdobeOrg%7CTS%3D1651681535#region=BAJ%C3%8DO">
              Dónde Encontrarnos
            </a>
          </div>
          <div className="footer-section">
            <a className="footer-link" href="https://www.dekalb.com.mx/es-mx/eventos-dekalb.html?adobe_mc=MCMID%3D32955331620841990133351718353533063110%7CMCORGID%3D8A720E205666B0F57F000101%2540AdobeOrg%7CTS%3D1651681522">
              Eventos
            </a>
          </div>
        </div>
        <div className="footer-bottom">
          <a
            href="https://www.dekalb.com.mx/es-mx/aviso-de-privacidad-.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Aviso de Privacidad
          </a>{" "}
          y{" "}
          <a
            href="https://www.dekalb.com.mx/es-mx/condiciones-generales-de-uso.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Condiciones Generales de Uso
          </a>
        </div>
      </Container>
    </Styledfooter>
  );
}

export default Nav;
