import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 750px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 1100px;
  }

  @media (min-width: 1200px) {
    max-width: 1300px;
  }
`;

export default function Container(props) {
  return (
    <StyledContainer className={`container_ ${props.className}`}>
      {props.children}
    </StyledContainer>
  );
}
