import React, { useState, useEffect, useCallback } from "react";
import ReactMapGL, { Source, Layer, WebMercatorViewport } from "react-map-gl";
import styled from "styled-components";
import Mexi from "../assets/mexicoHigh.json";
import SDK from "../sdk/DekalbSDK";
import LoaderSpinner from "../Utilities/SpinnerLoader";
import bbox from "@turf/bbox";
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it

// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
mapboxgl.workerClass = MapboxWorker;

const StyledMapa = styled.div`
  flex: 1;
  & > div {
    margin: auto;
    width: calc(100vw - 30px) !important;
    height: 400px !important;
    cursor: pointer !important;
  }

  .custom_tooltip {
    position: absolute;
    margin: 8px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    max-width: 300px;
    font-size: 10px;
    z-index: 9;
    pointer-events: none;
    border-radius: 5px;

    h1 {
      text-align: left !important;
      font-family: DIN_Italic_Bold;
      font-size: 15px !important;
      margin: 0;
    }
    h2 {
      text-align: left !important;
      margin: 10px 0 0 0;
      font-family: DIN_Italic_Normal;
      font-size: 15px !important;
    }
  }

  @media (min-width: 768px) {
    & > div {
      margin: auto;
      width: 500px !important;
      max-width: 720px !important;
      height: 500px !important;
    }
  }

  @media (min-width: 1200px) {
    flex: 0 1 60%;
    & > div {
      margin: auto;
      width: 600px !important;
      height: 600px !important;
    }
  }
`;

var colorArray = [
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
];

function Map(props) {
  const [hoverInfo, setHoverInfo] = useState(false);

  const [viewport, setViewport] = useState({
    longitude: -102.33436998727565,
    latitude: 23.792376254655768,
    zoom: 3.2,
  });

  const [settings] = useState({
    dragPan: false,
    dragRotate: false,
    scrollZoom: false,
    touchZoom: false,
    touchRotate: false,
    keyboard: false,
    doubleClickZoom: false,
  });
  const [state, setstate] = useState({
    show: false,
    fetch: false,
  });

  function capitalize(s) {
    if (typeof s !== "string") return "";
    var arr = s.split(" ").map((e) => e.toLowerCase());
    return arr.map((e) => e.charAt(0).toUpperCase() + e.slice(1)).join(" ");
  }

  useEffect(() => {
    setDefaultValuesMap();
  }, []);

  useEffect(() => {
    // console.log(props.state.fetch);
    // if (!props.state.fetch) {
    //   console.log(props.state);
    // }

    if (!props.state.fetch) {
      if (props.state.temporada.Id) {
        setstate({
          ...state,
          fetch: true,
        });
        getRegiones();
      } else {
        setstate({
          ...state,
          fetch: false,
          geoJson: false,
        });
      }
    } else {
      setstate({
        ...state,
        fetch: true,
      });
    }

    // if (!props.state.fetch) {
    //   if (props.state.temporada.Id !== undefined) getData(props.state);
    // }
  }, [props.state]);

  async function getRegiones() {
    setstate({
      ...state,
      fetch: true,
    });
    const { temporada, region } = props.state;
    if (region.Id) {
      getSubRegiones();
    } else {
      const regiones = await SDK.getRegionesEstadosTemporadas(temporada.Id);

      if (regiones.features && regiones.features.length >= 1) {
        setstate({
          ...state,
          fetch: false,
          geoJson: regiones,
        });
      } else {
        setstate({
          ...state,
          fetch: false,
          geoJson: false,
        });
      }
    }
  }

  async function getSubRegiones() {
    setstate({
      ...state,
      fetch: true,
    });
    const { subRegion, region, temporada } = props.state;
    if (subRegion.Id) {
      getEstados();
    } else {
      const SubRegiones = await SDK.getSubRegionesEstadosTemporadas(
        region.Id,
        temporada.Id
      );

      if (SubRegiones.features && SubRegiones.features.length >= 1) {
        setstate({
          ...state,
          fetch: false,
          geoJson: SubRegiones,
        });
      } else {
        setstate({
          ...state,
          fetch: false,
          geoJson: false,
        });
      }
    }
  }

  async function getEstados() {
    setstate({
      ...state,
      fetch: true,
    });
    const { subRegion, estado, temporada } = props.state;

    if (estado.Id) {
      getMunicipios();
    } else {
      const Estados = await SDK.getEstadosTemporadas(
        subRegion.Id,
        temporada.Id
      );
      if (Estados.features && Estados.features.length >= 1) {
        setstate({
          ...state,
          fetch: false,
          geoJson: Estados,
        });
      } else {
        setstate({
          ...state,
          fetch: false,
          geoJson: false,
        });
      }
    }
  }

  async function getMunicipios() {
    const { estado, temporada } = props.state;

    setstate({
      ...state,
      fetch: true,
    });

    if (estado.Id) {
      const Municipios = await SDK.getMunicipiosTemporadas(
        estado.Id,
        temporada.Id
      );

      if (Municipios.features && Municipios.features.length >= 1) {
        setstate({
          ...state,
          fetch: false,
          geoJson: Municipios,
        });
      } else {
        setstate({
          ...state,
          fetch: false,
          geoJson: false,
        });
      }
    }
  }

  // async function getMunicipio() {
  //   setstate({
  //     ...state,
  //     fetch: true,
  //   });

  //   const { temporada, municipio } = props.state;

  //   if (municipio.Id) {
  //     const municipioMapa = await SDK.getMunicipioTemporadas(
  //       municipio.Id,
  //       temporada.Id
  //     );
  //     console.log(municipioMapa);
  //   } else {
  //     setstate({
  //       ...state,
  //       fetch: false,
  //       geoJson: false,
  //     });
  //   }
  // }

  function renderTooltip() {
    if (hoverInfo) {
      if (
        hoverInfo.feature.properties.CNTRY ||
        hoverInfo.feature.properties.NAME_0
      ) {
        const { REGION, name, SUB_REGION, NAME_1, NAME_2 } =
          hoverInfo.feature.properties;

        return (
          <div
            className="custom_tooltip"
            style={{ left: hoverInfo.x, top: hoverInfo.y }}
          >
            {REGION ? <h1> {capitalize(REGION)}</h1> : null}
            {SUB_REGION ? <h1> {capitalize(SUB_REGION)}</h1> : null}
            {NAME_1 ? <h1> {capitalize(NAME_1)}</h1> : null}

            {name ? <h2>- {capitalize(name)}</h2> : null}
            {NAME_2 ? <h2>- {capitalize(NAME_2)}</h2> : null}
          </div>
        );
      }
    }
  }

  const colores = () => {
    if (!state.geoJson) {
      return Mexi.features.map((e, i) => {
        return [i, colorArray[i]];
      });
    } else {
      const arra = state.geoJson.features.map((e, i) => {
        if (e.properties.color) {
          return [i, e.properties.color];
        } else {
          return [i, colorArray[i]];
        }
      });

      return arra;
    }
  };
  // console.log(state.geoJson);
  const dataLayer = {
    id: "data",
    type: "fill",
    paint: {
      "fill-color": {
        property: "percentile",
        stops: colores(),
      },
      "fill-opacity": 0.6,
    },
  };

  // console.log(state.geoJson);
  // console.log(dataLayer);

  useEffect(() => {
    const { geoJson } = state;

    if (geoJson) {
      const [minLng, minLat, maxLng, maxLat] = bbox(geoJson);

      const vp = new WebMercatorViewport(viewport);
      const { longitude, latitude, zoom } = vp.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: 50,
        }
      );

      setViewport({
        ...viewport,
        longitude,
        latitude,
        zoom,
        transitionDuration: 1000,
      });
    } else {
      setDefaultValuesMap();
    }
  }, [state.geoJson]);

  function setDefaultValuesMap() {
    const widtWindow = window.innerWidth;

    if (widtWindow >= 992 && widtWindow < 1199) {
      setViewport({
        longitude: -101.99536848525709,
        latitude: 23.199535677274593,
        zoom: 3.5,
        transitionDuration: 1000,
      });
    } else if (widtWindow >= 1200) {
      setViewport({
        longitude: -101.88032792044496,
        latitude: 23.759985831796488,
        zoom: 3.7,
        transitionDuration: 1000,
      });
    }
  }

  function onChageGeo(e) {
    setViewport(e);
  }

  const onHover = useCallback((event) => {
    const {
      features,
      srcEvent: { offsetX, offsetY },
    } = event;

    const hoveredFeature = features && features[0];

    setHoverInfo(
      hoveredFeature
        ? {
            feature: hoveredFeature,
            x: offsetX,
            y: offsetY,
          }
        : null
    );
  }, []);

  function onClickMap(e) {
    const { features } = e;
    const clickedFeature = features && features[0];

    if (clickedFeature.properties.CNTRY || clickedFeature.properties.NAME_0) {
      const { ID_REGION, ID_SUB_REGION, ESTADO_Id, MUNICIPIO_Id } =
        clickedFeature.properties;

      const event = {
        target: {
          value: "",
        },
      };

      if (ID_REGION) {
        event.target.value = ID_REGION;
        props.onChangeRegion(event);
      } else if (ID_SUB_REGION) {
        event.target.value = ID_SUB_REGION;
        props.onChangeSubRegion(event);
      } else if (ESTADO_Id) {
        event.target.value = ESTADO_Id;
        props.onChangEstado(event);
      } else if (MUNICIPIO_Id) {
        event.target.value = MUNICIPIO_Id;
        props.onChangeMunicipio(event);
      }
    }
  }

  return (
    <StyledMapa>
      <ReactMapGL
        {...viewport}
        {...settings}
        width="100%"
        height="100%"
        onViewportChange={(e) => onChageGeo(e)}
        mapboxApiAccessToken={process.env.REACT_APP_API_MAPBOX}
        onHover={onHover}
        onClick={(e) => onClickMap(e)}
        mapStyle="mapbox://styles/yerim3/cktiw74mr23jg18lo9pi7djuz"
      >
        {state.fetch ? (
          <LoaderSpinner />
        ) : state.geoJson ? (
          <Source type="geojson" data={state.geoJson}>
            <Layer {...dataLayer} />
          </Source>
        ) : null}

        {renderTooltip()}
      </ReactMapGL>
    </StyledMapa>
  );
}

export default Map;
