import React, { useContext } from "react";
import { Context } from "../Context";
import styled from "styled-components";
import Container from "./Container";
// import dk_1 from "../assets/images/dk_1.png";
// import dk_2 from "../assets/images/dk_2.png";
// import dk_3 from "../assets/images/dk_3.png";
import dk_1N from "../assets/images/dk_1N.png";
import dk_2N from "../assets/images/dk_2N.png";
import dk_3N from "../assets/images/dk_3N.png";
import dekalb_logo from "../assets/images/dekalb.svg";

const StyledNuestrosProductos = styled.section`
  padding: 4rem 0px;

  .img-holder {
    position: relative;
    display: inline-block;
  }
  .img-holder img.imgBackgrund {
    display: block;
  }
  .img-holder .texto {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0, -50%);
    text-align: center;
    color: #fff;
  }

  .img-holder h4 {
    font-style: italic;
    font-weight: 900;
    font-size: 48px;
  }

  .texto-resultados-dekalb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .texto-resultados-dekalb div {
    height: 100%;
    margin: 30px 0px;
  }
  .producto {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
`;

export default function NuestrosProductos() {
  const [context] = useContext(Context);
  return (
    <StyledNuestrosProductos>
      <div className="texto-resultados-dekalb">
        <h1>NUESTROS PRODUCTOS</h1>
        <p>Haz click en el producto para obtener mayor información.</p>
      </div>
      <br />
      {context.id_region === 2 ? (
        <Container>
          <div className="row">
            <div className="col-12 offset-lg-1 col-lg-10  ">
              <div className="row">
                <div className="col-12 col-lg-4 producto">
                  <div className="img-holder">
                    <img style={{ width: "100%" }} src={dk_1N} alt="DEKALB" />
                    <div className="texto">
                      <img
                        style={{ width: "164px" }}
                        src={dekalb_logo}
                        alt="DEKALB"
                      />
                      <h4>DK-4018</h4>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.dekalb.com.mx/es-mx/productos-dekalb-/maiz.html#q%5B%5D=DK-4018"
                      >
                        <button className="simple-button-dekalb">
                          Ver más
                        </button>
                      </a>{" "}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-4 producto">
                  <div className="img-holder">
                    <img style={{ width: "100%" }} src={dk_2N} alt="DEKALB" />
                    <div className="texto">
                      <img
                        style={{ width: "164px" }}
                        src={dekalb_logo}
                        alt="DEKALB"
                      />
                      <h4>DK-2037</h4>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.dekalb.com.mx/es-mx/productos-dekalb-/maiz.html#q%5B%5D=DK-2037"
                      >
                        <button className="simple-button-dekalb">
                          Ver más
                        </button>
                      </a>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 producto">
                  <div className="img-holder">
                    <img style={{ width: "100%" }} src={dk_3N} alt="DEKALB" />
                    <div className="texto">
                      <img
                        style={{ width: "164px" }}
                        src={dekalb_logo}
                        alt="DEKALB"
                      />

                      <h4>DK-2061</h4>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.dekalb.com.mx/es-mx/productos-dekalb-/maiz.html#q%5B%5D=DK-2061" //Este está pendiente
                      >
                        <button className="simple-button-dekalb">
                          Ver más
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </Container>
      ) : (
        <Container>
          {/* PRODUCTOS PACÍFICO */}
          <div className="row">
            <div className="col-12 offset-lg-1 col-lg-10  ">
              <div className="row">
                <div className="col-12 col-lg-4 producto">
                  <div className="img-holder">
                    <img style={{ width: "100%" }} src={dk_1N} alt="DEKALB" />
                    <div className="texto">
                      <img
                        style={{ width: "164px" }}
                        src={dekalb_logo}
                        alt="DEKALB"
                      />
                      <h4>DK-4050</h4>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.dekalb.com.mx/es-mx/productos-dekalb-/maiz/product-detail-template.html/dk-4050-pacifico.html"
                      >
                        <button className="simple-button-dekalb">
                          Ver más
                        </button>
                      </a>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 producto">
                  <div className="img-holder">
                    <img style={{ width: "100%" }} src={dk_2N} alt="DEKALB" />
                    <div className="texto">
                      <img
                        style={{ width: "164px" }}
                        src={dekalb_logo}
                        alt="DEKALB"
                      />
                      <h4>DK-4055</h4>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.dekalb.com.mx/es-mx/productos-dekalb-/maiz/product-detail-template.html/dk-4055-pacifico.html"
                      >
                        <button className="simple-button-dekalb">
                          Ver más
                        </button>
                      </a>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 producto">
                  <div className="img-holder">
                    <img style={{ width: "100%" }} src={dk_3N} alt="DEKALB" />
                    <div className="texto">
                      <img
                        style={{ width: "164px" }}
                        src={dekalb_logo}
                        alt="DEKALB"
                      />

                      <h4>DK-2048</h4>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.dekalb.com.mx/es-mx/productos-dekalb-/maiz/product-detail-template.html/dk-2048-pacifico.html" //Este está pendiente
                      >
                        <button className="simple-button-dekalb">
                          Ver más
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </Container>
      )}
    </StyledNuestrosProductos>
  );
}
