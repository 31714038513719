import React from "react";
import styled from "styled-components";
// import imgBanner from "../assets/images/banner.jpg";
import imgBanner from "../assets/images/back_banner.png";
import dekalb_logo from "../assets/images/dekalb.svg";
import newCosecho from "../assets/images/logo_cosechometro_new.png";
// import LoginPortable from "./LoginPortable";

const StyledBanner = styled.section`
  .img-holder {
    position: relative;
    display: inline-block;
  }
  .img-holder {
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .img-holder .texto {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0, -50%);
    text-align: center;
    color: #fff;

    img {
      display: none;
    }
  }

  .img-holder .login-in-banner {
    position: absolute;
    top: 10px;
    width: 100%;
  }

  .mainTitle {
    font-style: italic;
    font-weight: 900;
    font-size: 1.2rem !important;
    text-align: left;
    margin-left: 5%;
  }

  .subTitle {
    font-weight: 500;
    font-size: 0.8rem !important;
    margin: -7px 0;
    text-align: left;
    margin-left: 5%;
  }

  @media (min-width: 400px) {
    .mainTitle {
      font-size: 1.5rem !important;
    }

    .subTitle {
      font-size: 1rem !important;
    }
  }

  @media (min-width: 576px) {
    .img-holder .texto {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        display: inline-block;
      }
    }
    .imgBanner {
      width: 100%;
      img {
        width: 60%;
        height: 60%;
      }
    }
    .textBanner {
      width: 60%;
    }

    @media (min-width: 650px) {
      .mainTitle {
        font-size: 2.5rem !important;
        margin-left: 8%;
      }

      .subTitle {
        font-size: 1.3rem !important;
        margin-left: 8%;
      }
      .imgBanner {
      width: 40%;
      img {
        width: 60%;
        height: 60%;
      }
    }
    .textBanner {
      width: 60%;
    }
    }
    @media (min-width: 1200px) {
      .mainTitle {
        font-size: 3rem !important;
        margin-left: 8%;
      }
      .subTitle {
        font-size: 2rem !important;
        margin-left: 8%;
      }
    }
    @media (min-width: 1800px) {
      .mainTitle {
        font-size: 4rem !important;
        margin-left: 8%;
      }
      .subTitle {
        font-size: 3rem !important;
        margin-left: 8%;
      }
    }
  }
`;

export default function Banner() {
  return (
    <StyledBanner>
      <div className="img-holder">
        <img
          className="imgBackgrund "
          src={imgBanner}
          alt="test"
          draggable="false"
        ></img>

        {/* <div className="login-in-banner">
          <div className="container">
            <div className="row">
              <div className="offset-lg-9 col-lg-2">
                <LoginPortable />
              </div>
            </div>
          </div>
        </div> */}
        <div className="texto">
          {/* <img
            style={{ width: "222px" }}
            src={dekalb_logo}
            alt="DEKALB"
            draggable="false"
          /> */}
          <div className="textBanner">
            <h1 className="mainTitle">COSECHÓMETRO</h1>
            <p className="subTitle">Es hora de sumar nuestros resultados</p>
            <p className="subTitle">
              y conocer el potencial de rendimiento DEKALB®
            </p>
          </div>
          <div className="imgBanner">
            <img src={newCosecho} alt="cosechometro_logo" />
          </div>
        </div>
      </div>
    </StyledBanner>
  );
}
