import React, { useEffect, useState, useContext } from "react";
import { Context } from "../Context";
import Container from "./Container";
import styled from "styled-components";
import yellowArrow from "../assets/images/arrow_yellow.svg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const StyledGallegry = styled.section`
  padding: 4rem 0px;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  .header {
    text-align: center;
  }

  .product-opcions {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    margin: auto;
    margin-bottom: 1rem;
    .option {
      cursor: pointer;
      display: flex;
      border-bottom: 2px solid white;
      align-items: center;
      opacity: 0.5;
      p {
        margin: 0.5rem;
      }
    }
    .current {
      opacity: 1;
      border-bottom: 2px solid #ffd22b;
    }
  }

  .carousel {
    .slide {
      background: transparent;
      img {
        width: 90%;
        border-radius: 13px;
      }
    }
  }

  .control-next:before {
    content: url(${yellowArrow}) !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
  }

  .carousel .control-prev.control-arrow::before {
    content: url(${yellowArrow}) !important;
    transform: rotate(180deg);
    border-right: 0px;
    border-top: 0 important;
    border-bottom: 0 !important;
    border-left: 0 !important;
  }

  .carousel .control-dots .dot {
    background: #ffd22b;
  }
`;

const Galeria = () => {
  const [state, setstate] = useState(0);
  const [images, setimages] = useState(IMAGENES);
  const [context] = useContext(Context);

  function showImage(imagenes) {
    var array = [];
    if (context.id_region && context.id_region === 2) {
      const MEDIA_IMGS = imagenes.filter((e) => e.type === 2);
      setimages(MEDIA_IMGS);
    } else if (context.id_region && context.id_region === 1) {
      const PACIFICO_IMGS = imagenes.filter((e) => e.type === 1);
      setimages(PACIFICO_IMGS);
    } else {
      setimages(imagenes);
    }

    return array;
  }

  useEffect(() => {
    if (state !== 0) {
      setstate(0);
    }

    showImage(IMAGENES);
  }, [context]);

  return (
    <StyledGallegry>
      <Container>
        <div className="header">
          <h1>GALERÍA</h1>
          <p>Conoce más sobre nuestros productos.</p>
        </div>

        <Carousel
          showThumbs={false}
          showStatus={false}
          onChange={(e) => setstate(e)}
          selectedItem={state}
        >
          {images.map((e, idx) => (
            <img src={e.img} key={idx} alt={e.kind + idx} />
          ))}
        </Carousel>
      </Container>
    </StyledGallegry>
  );
};

export default Galeria;

const IMAGENES = [
  {
    img: require("../assets/images/GaleriaCosechometro/DK-2061.png"),
    id: "DK-2061_20.png",
    kind: "media_altura",
    type: 2,
  },

  {
    img: require("../assets/images/GaleriaCosechometro/DK-2069.png"),
    id: "DK-2069_21.png",
    kind: "media_altura",
    type: 2,
  },

  {
    img: require("../assets/images/GaleriaCosechometro/DK-2037.png"),
    id: "DK-2037_22.png",
    kind: "media_altura",
    type: 2,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK-4018.png"),
    id: "DK-4018_23.png",
    kind: "media_altura",
    type: 2,
  },
  // FALTA ESTA IMAGEN
  // {
  //   img: require("../assets/images/GaleriaCosechometro/DKS.48g"),
  //   id: "DKS_48.png",
  //   kind: "media_altura",
  //   type: 2,
  // },
  {
    img: require("../assets/images/GaleriaCosechometro/DKS-319.png"),
    id: "DKS-319_24.png",
    kind: "media_altura",
    type: 2,
  },

  {
    img: require("../assets/images/GaleriaCosechometro/DKS-50.png"),
    id: "DKS-50_25.png",
    kind: "media_altura",
    type: 2,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DKS-55.png"),
    id: "DKS-55_26.png",
    kind: "media_altura",
    type: 2,
  },

  {
    img: require("../assets/images/GaleriaCosechometro/BRS-72.png"),
    id: "BRS-72_27.png",
    kind: "media_altura",
    type: 2,
  },

  {
    img: require("../assets/images/GaleriaCosechometro/DK1050_03.jpg"),
    id: "DK1050_03.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK2048_04.jpg"),
    id: "DK2048_04.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK4050_03.jpg"),
    id: "DK4050_03.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK4050_04.jpg"),
    id: "DK4050_04.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK4050_05.jpg"),
    id: "DK4050_05.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK4050_06.jpg"),
    id: "DK4050_06.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK4050_07.jpg"),
    id: "DK4050_07.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK4055_01.jpg"),
    id: "DK4055_01.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK4055_02.jpg"),
    id: "DK4055_02.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK4055_03.jpg"),
    id: "DK4055_03.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK4055_04.jpg"),
    id: "DK4055_04.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK4055_05.jpg"),
    id: "DK4055_05.jpg",
    kind: "pacifico",
    type: 1,
  },

  {
    img: require("../assets/images/GaleriaCosechometro/DK4055_07.jpg"),
    id: "DK4055_07.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK4055_08.jpg"),
    id: "DK4055_08.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK4055_09.jpg"),
    id: "DK4055_09.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK4055_10.jpg"),
    id: "DK4055_10.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK4055_11.jpg"),
    id: "DK4055_11.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DKS-26_02.jpg"),
    id: "DKS-26_02.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DKS-2805_2.jpg"),
    id: "DKS-2805_2.jpg",
    kind: "pacifico",
    type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK_4021.jpg"),
    id: "DK_4021.jpg",
    // kind: "pacifico",
    // type: 1,
  },
  {
    img: require("../assets/images/GaleriaCosechometro/DK_4020Y.jpg"),
    id: "DK_4020Y.jpg",
    // kind: "pacifico",
    // type: 1,
  },
];
