import React, { Component } from "react";
import LoaderSpinner from "../Utilities/SpinnerLoader";
import styled from "styled-components";
import dekalb_logo from "../assets/images/dekalb.svg";

const StyledCard = styled.div`
  background: #e8ebef;
  padding: 20px;
  border-radius: 10px;

  max-width: 400px;
  width: 100%;
  height: 470px;
  margin: auto;
  display: flex;
  flex-direction: column;

  .no-data {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    h1 {
      color: #727272;
      text-transform: uppercase;
      font-size: 30px !important;
    }
    h3 {
      color: #727272;
      font-size: 20px !important;
      margin: 20px 0 30px 0;
    }
    img {
      margin: 0 auto;
      max-width: 70%;
    }
  }

  .region {
    color: black;
    background: #fecc26;
    padding: 10px;
    border-radius: 10px;
    .hect-number {
      text-align: center;
      font-size: 30px;
      font-weight: bold;
    }

    h1 {
      text-align: left !important;
      color: black; //
      margin-bottom: 10px;
      font-size: 20px;
      text-transform: capitalize;
    }
    h2 {
      font-family: DIN_Normal_light;
      color: black;
      /* color: #727272; */
      font-size: 12px;
      text-transform: capitalize;
    }
  }
  .childs-container {
    padding-left: 10px;
    display: flex;
    margin-top: 10px;

    .line {
      margin-right: 10px;
      background-color: #b2b2b2;
      width: 2px;
      height: 100%;
    }

    .data {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .hect-number {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
      }

      h1 {
        text-align: left !important;
        color: black; //
        margin-bottom: 10px;
        font-size: 20px;
        text-transform: capitalize;
      }

      h2 {
        font-family: DIN_Normal_light !important;
        text-transform: capitalize;
        color: #727272 !important;
        font-size: 12px;
      }
    }
  }

  @media (min-width: 576px) {
    height: 560px;
    .hect-number {
      font-size: 30px !important;
    }

    .region {
      h2 {
        font-size: 15px;
      }
    }
    .childs-container {
      margin-top: 20px;
      .data {
        margin-bottom: 25px;

        h2 {
          font-size: 15px;
        }
      }
    }
  }
`;

export default class Cards extends Component {
  capitalize = (s) => {
    if (typeof s !== "string") return "";
    var arr = s.split(" ").map((e) => e.toLowerCase());
    return arr.map((e) => e.charAt(0).toUpperCase() + e.slice(1)).join(" ");
  };

  render() {
    // console.log(this.props.state);
    return (
      <StyledCard className="card__">
        {this.props.state.fetch ? (
          <LoaderSpinner color="white" />
        ) : this.props.state.region.Id ? (
          <>
            <div className="data region">
              <h1>Región {this.props.state.region.Descripcion}</h1>
              {this.props.state.rendimiento_region ? (
                <>
                  <h2 className="hect-number">
                    {this.props.state.rendimiento_region
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  </h2>
                  <h2>Hectáreas sembradas registradas</h2>
                </>
              ) : (
                <h2>Sin información</h2>
              )}
            </div>
            <div className="childs-container">
              <div className="line"></div>
              <div className="childs">
                {this.props.state.subRegion.Id ? (
                  <div className="data subRegion">
                    <h1>Subregión {this.props.state.subRegion.Descripcion}</h1>
                    {this.props.state.rendimiento_subRegion ? (
                      <>
                        <h2 className="hect-number">
                          {this.props.state.rendimiento_subRegion
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </h2>
                        <h2>Hectáreas sembradas registradas</h2>
                      </>
                    ) : (
                      <h2>Sin información</h2>
                    )}
                  </div>
                ) : null}

                {this.props.state.estado.Id ? (
                  <div className="data estado">
                    <h1> Estado {this.props.state.estado.Nombre}</h1>
                    {this.props.state.rendimiento_estado ? (
                      <>
                        <h2 className="hect-number">
                          {this.props.state.rendimiento_estado
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </h2>
                        <h2>Hectáreas sembradas registradas</h2>
                      </>
                    ) : (
                      <h2>Sin información</h2>
                    )}
                  </div>
                ) : null}

                {this.props.state.municipio.Id ? (
                  <div className="data muncipio">
                    <h1>
                      Municipio{" "}
                      {this.capitalize(this.props.state.municipio.Nombre)}
                    </h1>
                    {this.props.state.rendimiento_municipio ? (
                      <>
                        <h2 className="hect-number">
                          {this.props.state.rendimiento_municipio
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </h2>
                        <h2>Hectáreas sembradas registradas</h2>
                      </>
                    ) : (
                      <h2>Sin información</h2>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <div className="no-data">
            <h1>¡Bienvenido!</h1>
            <h3>
              Para poder ver la información de nuestros productos, primero
              tienes que seleccionar una región del país.
            </h3>
            <img src={dekalb_logo} alt="" />
          </div>
        )}
      </StyledCard>
    );
  }
}
