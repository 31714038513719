/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Container from "./Container";
import sdk from "../sdk/DekalbSDK";
import LoaderSpinner from "../Utilities/SpinnerLoader";
import { Context } from "../Context";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";

const StyledResultadosDekalb = styled.section`
  background: #f5f5f5;
  padding: 4rem 0px;
  /* * {
    border: 1px solid red;
  } */
  .title {
    text-align: center;
    margin-bottom: 20px;
    h1 {
      margin: 0;
    }
  }

  @media (min-width: 1200px) {
    .graficas {
      display: flex;
      .grafica__ {
        flex: 1;
        margin-right: 100px;
        display: flex;
        flex-direction: column;

        justify-content: flex-end;
        .grafica_body {
          flex: ${(props) => (props.bothGraph ? "1" : "0")};
          justify-content: ${(props) => (props.bothGraph ? "" : "center")};
          .grafica {
            flex: ${(props) => (props.bothGraph ? "0 0 80%" : "0")};
          }
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
`;

const GraficaBody = styled.section`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0px;
  }
  .title {
    margin-bottom: 10px;
  }
  .legends {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-bottom: 10px;
    .legend {
      margin-right: 20px;
      font-family: DIN_Italic_Bold !important;
    }
  }

  @media (min-width: 992px) {
    margin-bottom: 0px;
    .grafica_body {
      display: flex;

      .legends {
        flex: 0 0 20%;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-bottom: 0;
      }
      .grafica {
        flex: 0 0 80%;
      }
    }
  }
`;

const Graficas = (props) => {
  const [context, setContext] = useContext(Context);
  const [state, setstate] = useState({
    fetch: true,
    Maiz: false,
    Sorgo: false,
  });

  const [useProps, setuseProps] = useState({});

  useEffect(() => {
    const obj = {
      id_estado: context.id_estado,
      id_municipio: context.id_municipio,
      id_region: context.id_region,
      id_subReg: context.id_subReg,
      id_temporada: context.id_temporada,
    };
    getData(obj);
    setuseProps(obj);
  }, [context]);

  async function getData(obj) {
    if (JSON.stringify(obj) !== JSON.stringify(useProps)) {
      const data = await sdk.getRendimientoProductos(obj);
      await generateData(data.Informacion);
    }
  }

  async function generateData(array) {
    if (array) {
      let maizList = array.filter(({ TipoProducto }) => TipoProducto === 1);
      let sorgoList = array.filter(({ TipoProducto }) => TipoProducto === 2);

      const colores = {
        sorgo: [
          "rgba(249, 132, 4, 1)",
          "rgba(163, 87, 9, 1)",
          "rgba(78, 54, 32, 1)",
          "rgba(137, 120, 83, 1)",
        ],
        maiz: [
          "rgba(93, 130, 51, 1)",
          "rgba(42, 99, 83, 1)",
          "rgba(6, 68, 32, 1)",
          "rgba(251, 54, 64, 1)",
          "rgba(189, 45, 53, 1)",
          "rgba(140, 0, 0, 1)",
          "rgba(186, 198, 212, 1)",
          "rgba(212, 228, 246, 1)",
          "rgba(148, 158, 170, 1)",
          "rgba(255, 210, 43, 1)",
          "rgba(249, 178, 8, 1)",
        ],
      };

      var data = 0;

      let maizColores = await maizList.map((e, idx) => {
        if (idx <= colores.maiz.length - 1) {
          return colores.maiz[idx];
        } else {
          data = data === 3 ? 0 : data + 1;
          return colores.maiz[data];
        }
      });

      data = 0;

      let sorgoColores = await sorgoList.map((e, idx) => {
        if (idx <= colores.sorgo.length - 1) {
          return colores.sorgo[idx];
        } else {
          data = data === 11 ? 0 : data + 1;
          return colores.sorgo[data];
        }
      });

      if (JSON.stringify(context.productos) !== JSON.stringify(array)) {
        setContext({
          ...context,
          productos: array,
        });
      }

      setstate({
        ...state,
        fetch: false,
        estadisticas: array,

        Maiz: {
          name: "Maíz",
          list: maizList,
          valores: maizList.map(({ Valor }) => Valor),
          labels: maizList.map((columna) => columna.Nombre),
          colores: maizColores,
          hover: maizColores.map(
            (elx, idx) => elx.split(", 1)").join() + " 0.8)"
          ),
        },
        Sorgo: {
          name: "Sorgo",
          list: sorgoList,
          valores: sorgoList.map(({ Valor }) => Valor),
          labels: sorgoList.map((columna) => columna.Nombre),
          colores: sorgoColores,
          hover: sorgoColores.map(
            (elx, idx) => elx.split(", 1)").join() + " 0.8)"
          ),
        },
      });
    }
  }

  return state.fetch ? (
    <StyledResultadosDekalb>
      <Container>
        <LoaderSpinner />
      </Container>
    </StyledResultadosDekalb>
  ) : (
    <StyledResultadosDekalb
      bothGraph={state.Sorgo.list.length > 0 && state.Maiz.list.length > 0}
    >
      <Container>
        <div className="title">
          <h1>
            Rendimiento ponderado de los híbridos <br />
            ton/ha
          </h1>
        </div>
        {state.estadisticas ? (
          <div className="graficas">
            {state.Maiz.list.length > 0 ? (
              <GraficaInfo data={state.Maiz} />
            ) : null}
            {state.Sorgo.list.length > 0 ? (
              <GraficaInfo data={state.Sorgo} />
            ) : null}
          </div>
        ) : null}
      </Container>
    </StyledResultadosDekalb>
  );
};

const GraficaInfo = (data) => {
  const { name, list, labels, valores, colores, hover } = data.data
    ? data.data
    : {};

  const options = {
    maintainAspectRatio: false,

    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        color: "white",
        display: true,

        align: "center",
        anchor: "center",

        font: {
          weight: "bold",
          size: 11,
        },
      },
    },

    scales: {
      yAxes: [
        {
          gridLines: {
            lineWidth: 0,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "#fff",
            lineWidth: 0,
          },
        },
      ],
    },
  };

  return (
    <GraficaBody className="grafica__">
      <div className="title">
        <h1>{name}</h1>
      </div>
      <div className="grafica_body">
        <div className="legends">
          {list.map((response, idx) => (
            <p className="legend" key={idx}>
              {response.Nombre + " ®"}
            </p>
          ))}
        </div>
        <div className="grafica">
          <Bar
            data={{
              labels: labels,
              datasets: [
                {
                  data: valores,
                  backgroundColor: colores,
                  borderColor: colores,
                  borderWidth: 1,
                  hoverBackgroundColor: hover,
                  hoverBorderColor: hover,
                },
              ],
            }}
            options={options}
          />
        </div>
      </div>
    </GraficaBody>
  );
};

export default Graficas;
