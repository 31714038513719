import React, { useState, useEffect } from "react";

import styled from "styled-components";
import Container from "./Container";

// imagenes
import dekalb_logo from "../assets/images/dekalb.svg";
import bayer from "../assets/images/Bayer_Logo.png";

const StyledNav = styled.section`
  position: relative;
  .nav-top {
    background: rgb(62, 52, 34);

    .top-container {
      display: flex;
      justify-content: flex-end;
      padding: 0 24px !important;
      height: 32px;
      .nav-item {
        padding: 0.5rem 0;
        display: none;
        a {
          color: white;
          font-family: "HCo Gotham SSm";

          text-decoration: none;
        }
        margin-right: 2.5rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .nav-bottom {
    background-color: rgb(79, 67, 43);

    .bottom-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 84px;
      padding: 0 24px !important;

      .nav-item {
        height: 100%;
        display: none;

        align-items: center;

        padding: 0px 8px;

        font-size: 14px;
        cursor: pointer;

        // border-bottom: 2px solid rgb(79, 67, 43);
        &:hover {
          background-color: rgba(0, 0, 0, 0.15);
          // border-bottom: 2px solid white;
        }

        a {
          color: white;
          text-decoration: none;
          font-family: "HCo Gotham SSm";
          letter-spacing: 1px;
        }
      }

      .logo {
        height: 50px;
        width: 96px;
        img {
          max-width: 100%;
        }

        margin-right: 4rem;
      }

      .logo_bayer {
        width: 39px;
        height: 39px;
        img {
          max-width: 100%;
        }
      }
    }

    .responsive-container {
      display: flex;
      align-items: center;

      .nav-buttom {
        height: 14px;
        width: 15px;

        margin-right: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .line {
          height: 2px;
          width: 100%;
          border: 1px solid white;
          background: white;
        }
      }
    }
  }

  @media (min-width: 1023px) {
    .nav-buttom {
      display: none !important;
    }
    .nav-top {
      .top-container {
        .nav-item {
          display: block;
        }
      }
    }

    .nav-bottom {
      .bottom-container {
        .nav-item {
          display: flex;
        }
      }
    }
  }
`;

const StykledNav = styled.div`
  .overlay {
    ${(props) => (props.isOpen ? "z-index: 9000;" : "z-index: -1;")}

    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    width: 100vw;
    height: 100vh;
  }

  .nav {
    ${(props) => (props.isOpen ? "" : "opacity: 1 !important;")}
    ${(props) =>
      props.isOpen
        ? "transform: translateX();"
        : "transform: translateX(-100%);"}

    width: 260px;
    background: rgb(255, 255, 255);

    position: fixed;
    top: 0;
    left: 0;

    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    height: 100%;
    padding: 1rem;

    transition: transform 0.3s ease-out;

    .nav-item {
      height: 48px;
      width: 100%;
      a {
        text-decoration: none;
        line-height: 30px;
        font-size: 16px;
        color: black !important;
        font-family: "HCo Gotham SSm";
        padding: 1.6rem;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.15);
      }
    }
  }
`;

function Nav() {
  const [NavOpen, setNavOpen] = useState(false);

  function renderNav() {
    return (
      <StykledNav isOpen={NavOpen}>
        <div
          className="overlay"
          data-action="close"
          onClick={(e) => setNavOver(e)}
        ></div>
        <div className="nav">
          <div className="nav-item">
            <a
              href="https://www.dekalb.com.mx/es-mx/dekalb--contigo.html#q=&display=cards"
              target="_blank"
              rel="noopener noreferrer"
            >
              DEKALB® CONTIGO
            </a>
          </div>
          <div className="nav-item">
            <a
              href="https://www.dekalb.com.mx/es-mx/productos-dekalb-/maiz.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              PRODUCTOS
            </a>
          </div>
          <div className="nav-item">
            <a
              href="https://www.dekalb.com.mx/es-mx/resultados-probados.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              RESULTADOS PROBADOS
            </a>
          </div>
          
          <div className="nav-item">
            <a
              href="https://www.dekalb.com.mx/es-mx/acerca-de-dekalb-.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              ACERCA DE DEKALB®
            </a>
          </div>
          <div className="nav-item">
              <a
                href="https://www.dekalb.com.mx/es-mx/eventos-dekalb.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                EVENTOS
              </a>
            </div>
          <div className="nav-item">
            <a
              href="https://www.dekalb.com.mx/es-mx/contactenos.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              CONTÁCTANOS
            </a>
          </div>
          <div className="nav-item">
            <a
              href="https://www.dekalb.com.mx/es-mx/donde-encontrarnos-.html#region=BAJ%C3%8DO"
              target="_blank"
              rel="noopener noreferrer"
            >
              DÓNDE ENCONTRARNOS
            </a>
          </div>
        </div>
      </StykledNav>
    );
  }

  let setNavOver = (e) => {
    if (e.target.dataset.action) {
      setNavOpen(false);
    }
  };

  useEffect(() => {
    if (NavOpen) {
      document.body.style.overflow = "hidden";
      document.body.scroll = "no";
    } else {
      document.body.style.overflow = "scroll";
      document.body.scroll = "yes";
    }
  }, [NavOpen]);

  return (
    <StyledNav>
      {renderNav()}
      <div className="nav-top">
        <Container>
          <div className="top-container">
            <div className="nav-item">
              <a
                href="https://www.dekalb.com.mx/es-mx/contactenos.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                CONTÁCTANOS
              </a>
            </div>
            <div className="nav-item">
              <a
                href="https://www.dekalb.com.mx/es-mx/donde-encontrarnos-.html#region=BAJ%C3%8DO"
                target="_blank"
                rel="noopener noreferrer"
              >
                DÓNDE ENCONTRARNOS
              </a>
            </div>
          </div>
        </Container>
      </div>
      <div className="nav-bottom">
        <Container>
          <div className="bottom-container">
            <div className="responsive-container">
              <div className="nav-buttom" onClick={() => setNavOpen(!NavOpen)}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
              <div className="logo">
                <a href="https://www.dekalb.com.mx/es-mx.html">
                  <img src={dekalb_logo} alt="dekalb_logo" />
                </a>
              </div>
            </div>

            <div className="nav-item">
              <a
                href="https://www.dekalb.com.mx/es-mx/dekalb--contigo.html#q=&display=cards"
                target="_blank"
                rel="noopener noreferrer"
              >
                DEKALB® CONTIGO
              </a>
            </div>
            <div className="nav-item">
              <a
                href="https://www.dekalb.com.mx/es-mx/productos-dekalb-/maiz.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                PRODUCTOS
              </a>
            </div>
            <div className="nav-item">
              <a
                href="https://www.dekalb.com.mx/es-mx/resultados-probados.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                RESULTADOS PROBADOS
              </a>
            </div>
            <div className="nav-item">
              <a
                href="https://www.dekalb.com.mx/es-mx/eventos-dekalb.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                EVENTOS
              </a>
            </div>
            <div className="nav-item">
              <a
                href="https://www.dekalb.com.mx/es-mx/acerca-de-dekalb-.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                ACERCA DE DEKALB®
              </a>
            </div>

            <div className="logo_bayer">
              <a href="https://www.bayer.com/en/" target="_blank">
                <img src={bayer} alt="bayer" />
              </a>
            </div>
          </div>
        </Container>
      </div>
    </StyledNav>
  );
}

export default Nav;
