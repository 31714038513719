// import React, { Component } from "react";

// import Experiencias from "./components/Experiencias";

// export class App extends Component {
//   constructor(props) {
//     super(props);
//     this.state =
//   }

//   render() {
//     return (

//     );
//   }
// }
// export default App;
import React, { useState } from "react";
import "./App.css";
import styled from "styled-components";

// Components
import Navbar from "./components/Nav";
import Footer from "./components/Footer";
import Banner from "./components/Banner";
import ResultadosDekalb from "./components/ResultadosDekalb";
import NuestrosProductos from "./components/NuestrosProductos";
import Galeria from "./components/Galeria";
import GraficaEstado from "./components/GraficaEstado";
import Graficas from "./components/Graficas";
import Experiencias from "./components/Experiencias";
import { Context } from "./Context";

const StyledApp = styled.div`
  // border: red solid 1px;

  // div:nth-child(even) {
  //   background: #f5f5f5;
  // }
`;

const App = () => {
  const [context, setContext] = useState({
    id_estado: -1,
    id_municipio: -1,
    id_region: -1,
    id_subReg: -1,
    id_temporada: -1,
    productos: [],
  });
  // console.log(context)
  return (
    <Context.Provider value={[context, setContext]}>
      <StyledApp>
        <Navbar />
        <Banner />
        <ResultadosDekalb />
        <GraficaEstado setContext={setContext} context={context} />
        <Graficas />
        <Experiencias setContext={setContext} state={context} />
        <Galeria state={context} />
        <NuestrosProductos />
        <Footer />
      </StyledApp>
    </Context.Provider>
  );
};

export default App;
