const serverAddress = process.env.REACT_APP_API_ADDRESS;

export default class sidebarAPI {
  // constructor() {}

  static async request(method, endpoint, payload, token) {
    if (method !== "get" && method !== "GET") {
      let headers = {};
      headers["Content-Type"] = "application/json";

      if (token) {
        headers["Authorization"] = "Bearer " + token;
      }

      return fetch(serverAddress + endpoint, {
        method: method,
        headers: headers,
        body: JSON.stringify(payload),
      })
        .then((response) => {
          console.log("RESPONSE", response)
          return response.json();
        })
        .then((result) => {
          return result;
        })
        .catch((error) => {
          return error;
        });
    } else {
      let headers = {};
      headers["Content-Type"] = "application/x-www-form-urlencoded";

      if (token) {
        headers["Authorization"] = "Bearer " + token;
      }

      return fetch(serverAddress + endpoint, {
        method: method,
        headers: headers,
      })
        .then((response) => {
          if (!response.ok) {
            console.log("RESPONSE", response)
            return response.status;
          }
          return response.json();
        })
        .then((result) => {
          return result;
        })
        .catch((error) => {
          return error;
        });
    }
  }

  static getTemporadas() {
    return this.request("GET", `temporadas`, "", null).then((res) => {
      return res;
    });
  }

  static getRegionPorTemprada(id) {
    return this.request(
      "GET",
      `regiones_temporada/${id <= 0 ? -1 : id}`,
      "",
      null
    ).then((res) => {
      return res;
    });
  }

  static getRegiones() {
    return this.request("GET", `regiones`, "", null).then((res) => {
      return res;
    });
  }

  static getSubRegiones(id, temporada) {
    return this.request(
      "GET",
      `regiones/detalle/${id}/temporada/${temporada ? temporada : -1}`,
      "",
      null
    ).then((res) => {
      return res;
    });
  }

  static getEstadosByRegion(id, temporada) {
    return this.request(
      "GET",
      `subregion/detalle/${id}/temporada/${temporada ? temporada : -1}`,
      "",
      null
    ).then((res) => {
      return res;
    });
  }

  static getMunicipios(id, temporada) {
    return this.request(
      "GET",
      `municipio/estado/${id}/temporada/${temporada ? temporada : -1}`,
      "",
      null
    ).then((res) => {
      return res;
    });
  }

  static getProductos(id) {
    return this.request("GET", `productos/municipio/${id}`, "", null).then(
      (res) => {
        return res;
      }
    );
  }
  static getHectareas(id) {
    return this.request("GET", `products/${id}`, "", null).then(
      (res) => {
        return res;
      }
    );
  }

  static getRendimientos(data) {
    var obj = {
      id_temporada: data.temporada.Id ? data.temporada.Id : -1,
      id_region: data.region.Id ? data.region.Id : -1,
      id_subReg: data.subRegion.Id ? data.subRegion.Id : -1,
      id_estado: data.estado.Id ? data.estado.Id : -1,
      id_municipio: data.municipio.Id ? data.municipio.Id : -1,
    };

    return this.request(
      "GET",
      `rendimiento_siembra/temporada/${obj.id_temporada}/region/${obj.id_region}/subregion/${obj.id_subReg}/estado/${obj.id_estado}/municipio/${obj.id_municipio}`,
      "",
      null
    ).then((res) => {
      return res;
    });
  }

  static getRendimientoProductos(data) {
    var obj = {
      id_estado: data.id_estado <= 0 ? -1 : data.id_estado,
      id_municipio: data.id_municipio <= 0 ? -1 : data.id_municipio,
      id_region: data.id_region <= 0 ? -1 : data.id_region,
      id_subReg: data.id_subReg <= 0 ? -1 : data.id_subReg,
      id_temporada: data.id_temporada <= 0 ? -1 : data.id_temporada,
    };

    return this.request(
      "GET",
      `rendimiento_productos/temporada/${obj.id_temporada}/region/${obj.id_region}/subregion/${obj.id_subReg}/estado/${obj.id_estado}/municipio/${obj.id_municipio}`,
      "",
      null
    ).then((res) => {
      return res;
    });
  }

  static getComentarios(data, id) {
    var obj = {
      id_estado: data.id_estado === 0 ? -1 : data.id_estado,
      id_municipio: data.id_municipio === 0 ? -1 : data.id_municipio,
      id_region: data.id_region === 0 ? -1 : data.id_region,
      id_subReg: data.id_subReg === 0 ? -1 : data.id_subReg,
    };

    return this.request(
      "GET",
      `comentarios_generales/region/${obj.id_region}/subregion/${obj.id_subReg}/estado/${obj.id_estado}/municipio/${obj.id_municipio}/producto/${id}`,
      "",
      null
    ).then((res) => {
      return res;
    });
  }

  static getRegionesEstadosTemporadas(temporada) {
    return this.request("GET", `/estados_region/${temporada}`, "", null).then(
      (res) => {
        return res;
      }
    );
  }

  static getSubRegionesEstadosTemporadas(idRegion, idTemporada) {
    return this.request(
      "GET",
      `estados_subRegion/${idRegion}/${idTemporada}`,
      "",
      null
    ).then((res) => {
      return res;
    });
  }

  static getEstadosTemporadas(idSubRegion, idTemporada) {
    return this.request(
      "GET",
      `estados_temporadas/${idSubRegion}/${idTemporada}`,
      "",
      null
    ).then((res) => {
      return res;
    });
  }

  static getMunicipiosTemporadas(idEstado, idTemporada) {
    return this.request(
      "GET",
      `municipios_temporadas/${idEstado}/${idTemporada}`,
      "",
      null
    ).then((res) => {
      return res;
    });
  }
  static getMunicipioTemporadas(idMunicipio, idTemporada) {
    return this.request(
      "GET",
      `municipio_temporadas/${idMunicipio}/${idTemporada}`,
      "",
      null
    ).then((res) => {
      return res;
    });
  }
}
